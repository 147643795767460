import React from "react";
import { Helmet } from "react-helmet";
import WhoWeAre from "../../Assets/aboutUs/WhoWeAre.png";
import AboutImg from '../../Assets/aboutUs/About-us.png'

import {
  CustomContainer,
  BodyImg,
  H4
} from "./StyledAboutUs.js";

export default class AboutUs extends React.Component {
  render() {    
    return (
      <div>
        <Helmet>
          <title>
            Ebullientech Journeys - The Elite Team
          </title>
          <meta
            name="description"
            content="We are a start-up India recognised, Indian Chamber of Commerce certified software and mobile app company. At Ebullientech, we leave no stone unturned to provide you with highest quality of services."
          />
          <meta
            property="og:image"
            content="https://www.ebullientech.io/favicon.png"
          />
          {/*<script className="structured-data-list" type="application/ld+json">
            {structuredJSON}
          </script>*/}
        </Helmet>

        <img src={AboutImg} alt="Ebullientech About Us" title="Ebullientech About Us" style ={{width: '100%'}}/>

        <CustomContainer>
          <div className="container">
            <section
              style={{
                overflow: "hidden",
                background: "#fff",
                color: "#4e4e4e",
                textAlign: "justify",
                fontSize: "14px",
                fontFamily: 'MuseoSans-500'
              }}
            >
              <h3>
                <strong>
                  <div style={{ position: "relative" }}>
                    <BodyImg src={WhoWeAre} alt="" />
                  </div>
                  <H4>Who we are and what we do</H4>
                </strong>
              </h3>

              <p>
                <a href="/">Ebullientech</a> (
                <em>
                  <strong>ee-boo-lien-tech</strong>
                </em>
                )...., the name is neologised after two words -{" "}
                <strong>Ebullience</strong> and <strong>Technology</strong>.
                According to the Oxford Dictionary, the word ebullience means "the quality of being cheerful and full of energy". As the name suggests, we are a team of architects, engineers, dreamers, doers, aspirants, writers, and visionaries filled with great energy. A self-funded startup with two women founders, both hailing from Kolkata. Our other team members are from Mumbai, Allahabad, Lucknow, Surat, Ahmedabad, Bhavnagar, Jodhpur, Bangalore, and Indore. We harbour dreams where the sky is the limit.
              </p>
              <p>
                We embarked upon our journey with two indie products - {" "}
                <em>R2D Freight</em> and <em>KidzByte</em>. Until now, we've designed, developed and launched/released software applications for clients and startups like{" "}
                 <em>Dance with Madhuri</em>, <em>Madhuri Dixit Nene</em>, <em>Jimmy Mistry</em>, <em>Hoichoi TV (SVF)</em>, <em>TopQuiz (Nazara Games)</em>, <em>MobCast</em>, <em>GOQII</em>, <em>NeoGrowth</em>, <em>JET Freight Logistics</em>, <em>Ava Chemicals</em>, <em>Gini & Jony</em>, <em>Ascend International.</em>
              </p>
            </section>
            <section
              style={{
                color: "#4e4e4e",
                textAlign: "justify",
                fontSize: "14px",
                fontFamily: 'MuseoSans-500'
              }}
            >
              <p>
              Like all other startups, our lives hardly have any work-life balance. Still, we love it that way. We love burning the midnight oil over new releases, savour Sunday matinees over game-changing technology trends and at the same time, fancy spoiling ourselves for random weekend gateways - totally adore our nightly Marine Drive adventures.
              </p>
              <p>
                As is the case with every other business globally, we, too, had been deeply affected by the Covid-19 pandemic and still struggling with it. Yet, we can proudly say that 2020 was one of our strongest years and 2021 would be better. Last year we worked on multiple technology stacks, like <em>MEAN & MERN</em>,{" "}
                <em>Android Kotlin</em>, <em>iOS Swift & Viper</em> and{" "}
                <em>Serverless</em> tech. We also published our first ever product, the{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://coronaweb.ebullientech.io"
                >
                  <em>Covid-19 Tracker</em>
                </a>
                , and we are on the way to release our custom eCommerce platform. Last Diwali, we gifted our team with family medical cover and solid work from home amenities.
                </p>
                <p>
                 Last year we also earned ICC (Indian Chamber of Commerce) membership, became a Govt. of India recognised startup in the "IT Services and Application Development" sector, opened the Kolkata branch and applied for Trademark. This year, we bagged our first-ever Bussiness Analytics and Healthcare projects, and we intend to build the Healthcare product as robust as UK NHS (United Kingdom National Health Service). We also successfully implemented some best in class{" "}
                 <a href="/processes-and-tools">processes and tools</a> to improve internal/external communication, project management, milestone tracking, source code management, local and cloud infrastructure. We expanded our <a href="/our-services">services</a> with{" "}
                 Business Analytics, Design & UX and Legal Process Outsourcing. We believe knowledge is the greatest power one can have and technology empowers us to apply our lore to transform businesses. We firmly believe engineering combined with compassion has the potential to grow any enterprise or product exponentially. We take up a business puzzle and make it our life's problem and design an effective solution with our acumen. We treat our clients like our own people, and in return, they take care of us through the thick and thin. We are also highly fortunate to have <a href="/key-people">mentors and advisors</a> like{" "}
                <em>Mr Bhaskar Sen (Co-founder of Sika Qualcrete, now known as Sika India),Ms Shakuntala Sen, Mr Sanjay Mehta, Mr Chetan D'souza.</em>{" "}
                For us, communication is as crucial as the delivery itself; hence we walk that extra mile to make sure our customer customers can{" "}
                <a href="/contact-us">reach out</a> to us any time of the day across timezones.
              </p>
              <p>&nbsp;</p>
            </section>

            {/*<H4>Our Toolbox</H4>
                    <p><strong>Core Technology, Frameworks, and UI Development – </strong>Python, Node.js, Java, PHP, Ruby, MySQL, PostgreSQL, MongoDB, Elasticsearch, Redis, Aerospike, AngularJS, Angular, ReactJS, Android, iOS, Django, Flask, Express.js, Nest.js, Spring Boot, Codeigniter, Laravel, Zend, Lumen, Phalcon<br />
                        <strong>Cloud Computing – </strong>Amazon Web Services, Google Cloud Platform<br />
                        <strong>Artificial Intelligence – </strong>Amazon Rekognition, Google Vision API, Machine Learning<br />
                        <strong>Digital Marketing and Analytics – </strong>Google AdWords, Google Keyword Planner, Google Analytics, MailChimp, Sendy Adobe Marketing Cloud, CleverTap, Localytics, Firebase Google Analytics, Flurry, Facebook Ads, LinkedIn Ads, Prediction.io<br />
                        <strong>Video Tech Solutions, Serverless, &amp; CDN – </strong>AWS Elemental Media Converter &amp; MediaTailor, BrightCove, BitMovin, Beamr, MUX, VideoJS, YOUBORA, Kaltura, Widevine, FairPlay, Lambda, CloudFront, Akamai<br />
                        <strong>Content Management Systems – </strong>WordPress, Shopify, Magento, Drupal, Wix, Squarespace, Typo3
                    </p> */}
          </div>
        </CustomContainer>
      </div>
    );
  }
}
