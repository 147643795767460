import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import './Footer.css'
// import styled from 'styled-components'

import digitI from '../../Assets/certi/Digital_India.png';
import startI from '../../Assets/certi/Startupindia.png';
import iccF from '../../Assets/certi/ICC_Full.png';
import msmeI from '../../Assets/certi/UdyogaadharMSME.jpg';

import { Partner2, PartnerImg, PartnerText,LinkIcon} from "./StyleFooter.js";

// const Hr =styled.hr`
//     background-color:rgba(255, 255, 255, 0.12) !important;
// `

function goTop(){
  window.scrollTo({top: 0, behavior: 'smooth'});
}

export default class Footer extends React.Component {
    render() {
        return (


                <div style={{ backgroundColor: "#1F1F1F" ,fontFamily:"MuseoSans-500" }} >
                    <Container >
                        <Row style ={{padding:"1em"}}>
                            <Col lg={2} className="col-6">
                                <h3 className = "footer" >
                                    Phone
                                </h3>                                
                                <a href="tel:+919820131134"><p className = "footer-content">+91-9820131134</p></a>
                            </Col>
                            <Col lg={2} className="col-6">
                                <h3 className = "footer" >
                                    Email
                            </h3>
                            <a href = "mailto:info@ebullientech.io"><p className = "footer-content" >info[at]ebullientech[dot][io]</p></a>

                            </Col>
                            <Col lg={3} className="col-6 col-pad-footer">
                                <h3 className = "footer" >
                                    Locations
                            </h3>
                            <p className = "footer-content" >Navi Mumbai – 400709</p>
                            <p className = "footer-content" >Camac Street, Kolkata – 700016</p>
                            </Col>
                            <Col lg={2} className="col-6 col-pad-footer">
                                <h3 className = "footer" >
                                    Other
                            </h3>
                                <a
                                rel="noopener noreferrer"
                                href="/privacy-policy">
                                <p className = "footer-content" >Privacy Policy</p></a>
                                <a
                                rel="noopener noreferrer"
                                href="/terms-and-conditions">
                                <p className = "footer-content" >Terms and Conditions</p></a>
                                <a
                                rel="noopener noreferrer"
                                href="/refund-policy">
                                <p className = "footer-content" >Refund Policy</p></a>
                            </Col>
                            <Col lg={3} className="col-12 text-center col-pad-footer-sos" >
                              <div>
                                  <a href="https://www.facebook.com/ebullientech"><LinkIcon className="fab fa-facebook-f" /></a>
                                  <a href="https://www.linkedin.com/company/ebullientech"><LinkIcon className="fab fa-linkedin-in" /></a>
                                  <a href="https://www.instagram.com/ebullientech.io"><LinkIcon className="fab fa-instagram" /></a>
                                  <a href="https://api.whatsapp.com/send?phone=919830679611"><LinkIcon className="fab fa-whatsapp" /></a>
                                  <LinkIcon className="far fa-arrow-alt-circle-up cursor-class" style={{color:"gray"}} onClick={goTop} />
                              </div>
                            </Col>
                        </Row>
                    </Container>
                    <section className="footer-member-sec">
                        <Container>
                            <Partner2 className="text-center ">Memberships, Certifications and Recognitions</Partner2>
                            <Row className="text-center">
                                <Col lg={3} className="col-6">
                                    <div style={{ backgroundColor: "#ffffff", height: '100%' }}>
                                        <PartnerText>
                                            <PartnerImg alt="ICC" src={iccF} width="100%" />
                                        </PartnerText>
                                    </div>
                                </Col>
                                <Col lg={3} className="col-6">
                                    <div style={{ backgroundColor: "#ffffff", height: '100%' }}>
                                        <PartnerText>
                                            <PartnerImg alt="Startupindia" src={startI} width="100%" />
                                        </PartnerText>
                                    </div>
                                </Col>
                                <Col lg={3} className="col-6">
                                    <div style={{ backgroundColor: "#ffffff", height: '100%' }}>
                                        <PartnerText>
                                            <PartnerImg alt="UdyogaadharMSME" src={msmeI} className="footer-msme-image-w" />
                                        </PartnerText>
                                    </div>
                                </Col>
                                <Col lg={3} className="col-6">
                                    <div style={{ backgroundColor: "#ffffff", height: '100%' }}>
                                        <PartnerText>
                                            <PartnerImg alt="DigitalIndia" src={digitI} className="footer-digit-image-w" />
                                        </PartnerText>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>

                    <div className = "footer-bar"  style ={{fontFamily:'MuseoSans-300'}}>© Ebullientech Interactive LLP {(new Date().getFullYear())} - All rights reserved.</div>
                </div>



        )
    }
}
